<div style="background-repeat: repeat-y">
  <div class="col-md-12">
    <div class="row">
      <div class="col-md">
        <div class="box-cookies hide">
          <label class="msg-cookies"
            >Nós usamos cookies e outras tecnologias semelhantes para melhorar a
            sua experiência em nossa plataforma.Ao utilizar nossa plataforma,
            você concorda plenamente,informamos ainda que não compartilhamos
            seus dados com terceiros em caso de duvidas consulte nossa
            <a routerLink="/politica-privacidade">
              <b>Política de Privacidade</b></a
            ></label
          >
          <button class="btn-cookies">Concordar</button>
        </div>
      </div>
    </div>

    <!--CARD-->
    <div class="row">
      <div class="col"></div>
      <div class="col-md-11">
        <div id="carouselExampleFade" class="carousel" data-ride="carousel">
          <div class="carousel-inner">
          
         <!--
            <div class="carousel-item ">
              <a
                href="https://www.even3.com.br/30-anos/"
                title="30 Anos Morada"
                target="_blank"
              >
                <img
                  class="col-md-12"
                  src="../../assets/img/2023/niver30anos.png"
                  height="370px"
              /></a>
            </div>
            

            <div class="carousel-item active">
              <a routerLink="/curso-casais">
                <img
                  class="col-md-12"
                  src="../../assets/img/novos/cards/oficina_2024.jpeg"
                  height="370px"
              /></a>
            </div>                   
            -->
            <!--
            <div class="carousel-item">
              <a
                href="https://api.whatsapp.com/send?l=pt_BR&phone=55032984324998&text=[SITE]: Contato Pelo Site"
                title="Visita Pr.Carlos"
                target="_blank"
              >
                <img
                  class="col-md-12"
                  src="../../assets/img/2023/pastor_jovem2023.jpeg"
                  height="370px"
              /></a>
            </div>
            -->
            <div class="carousel-item active">
              <a routerLink="/encontro-casais">
                <img
                  class="col-md-12"
                  src="../../assets/img/novos/novos/cards/convite.png"
                  height="370px"
              /></a>
            </div>    

            <div class="carousel-item">
              <a routerLink="">
                <img
                  class="col-md-12"
                  src="../../assets/img/2023/quarta_2023.jpeg"
                  height="370px"
              /></a>
            </div>

            <div class="carousel-item">
              <a routerLink="">
                <img
                  class="col-md-12"
                  src="../../assets/img/2023/circulo_oracao_2023.jpeg"
                  height="370px"
              /></a>
            </div>
<!--
            <div class="carousel-item">
              <a routerLink="">
                <img
                  class="col-md-12"
                  src="../../assets/img/novos/cards/oracao_sexta.jpeg"
                  height="370px"
              /></a>
            </div>
          -->
<!--
            <div class="carousel-item">
              <a routerLink="">
                <img
                  class="col-md-12"
                  src="../../assets/img/2023/jovens_novembro.jpeg"
                  height="370px"
              /></a>
            </div>
         

            <div class="carousel-item">
              <a routerLink="/nossa-agenda">
                <img
                  class="col-md-12"
                  src="../../assets/img/2023/Domingo_jejum_2023.jpeg"
                  height="370px"
              /></a>
            </div>
            <div class="carousel-item">
              <a routerLink="/nossa-agenda">
                <img
                  class="col-md-12"
                  src="../../assets/img/novos/cards/Escola-dominical.png"
                  height="370px"
              /></a>
            </div>
             
            <div class="carousel-item">
              <a href="https://www.webradioetvcriativa.com.br/" target="_blank">
                <img
                  class="col-md-12"
                  src="../../assets/img/2023/cafe_com_jesus.jpeg"
                  height="370px"
              /></a>
            </div>
            -->
            <!--
                
-->
            <div class="carousel-item">
              <a
                href="https://www.google.com.br/maps/@-21.7416378,-43.3744249,3a,75y,32.52h,64.56t/data=!3m6!1e1!3m4!1sD8h6bxziytkJnut-Z7j4ug!2e0!7i13312!8i6656"
                title="Localização"
              >
                <img
                  class="col-md"
                  src="../../assets/img/novos/cards/LOCALIZACAO.png"
                  height="370px"
              /></a>
            </div>

            <div class="carousel-item">
              <a href="https://t.me/moradadedeus" target="_blank">
                <img
                  class="col-md-12"
                  src="../../assets/img/novos/cards/TELEGRAM.png"
                  height="370px"
              /></a>
            </div>

            <div class="carousel-item">
              <a
                href="https://www.instagram.com/moradajfoficial/"
                target="_blank"
              >
                <img
                  class="col-md-12"
                  src="../../assets/img/novos/cards/Instagram.png"
                  height="370px"
              /></a>
            </div>

            <div class="carousel-item">
              <a routerLink="" title="Pix">
                <img
                  class="col-md"
                  src="../../assets/img/novos/cards/pix-SITE.png"
                  height="370px"
              /></a>
            </div>

            <div class="carousel-item">
              <a routerLink="/visitante" title="Fazer Cadastro">
                <img
                  class="col-md"
                  src="../../assets/img/novos/cards/VISITANTES.png"
                  height="370px"
              /></a>
            </div>

            <div class="carousel-item">
              <a routerLink="/consulta" title="Visualizar">
                <img
                  class="col-md-12"
                  src="../../assets/img/novos/cards/servicosssDD.png"
                  height="370px"
              /></a>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleFade"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Anterior</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleFade"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Próximo</span>
          </a>
        </div>
      </div>
      <div class="col"></div>
    </div>

    <!--HR-->
    <div class="row">
      <div class="col"></div>
      <div class="col-md-12">
        <hr
          style="
            color: #051f5c;
            background-color: #051f5c;
            height: 1px;
            padding-top: 2px;
          "
        />
      </div>
      <div class="col"></div>
    </div>
  </div>

  <!--TEXTO-->
  <div class="col-md-12">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-13">
        <label class="fonte" style="font-size: 22px; text-align: center">
          Seja bem vindo a nossa Morada!
          <br />
          A igreja do Amor
          <br />
          Aguardamos você por aqui!
          <br />
          Atenciosamente.
          <br />
          Pr.Geraldo e Pr.Rodrigo
        </label>
      </div>
      <div class="col"></div>
    </div>
  </div>

  <!--HR-->
  <div class="col-md-12">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-12">
        <hr
          style="
            color: #051f5c;
            background-color: #051f5c;
            height: 1px;
            padding-top: 2px;
          "
        />
      </div>
      <div class="col"></div>
    </div>
  </div>

  <!--inscricao

  <div class="col-md-12">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-13">
        <label class="fonte" style="font-size: 22px; text-align: center"
          style="font-size: 22px; text-align: center; color: rgb(107, 41, 41);font-family: 'Montserrat';margin: 20px; ">
          <b> <a href="https://www.even3.com.br/30-anos/" target="_blank" style="color: red;"> INSCRIÇÃO ANIVERSÁRIO MORADA (30 Anos) </a>
          </b>
        </label>
      </div>
      <div class="col"></div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-13">
        <label class="fonte" style="font-size: 22px; text-align: center"
        style="font-size: 22px; text-align: center; color: rgb(107, 41, 41);font-family: 'Montserrat';margin: 20px; ">
        <b> <a routerLink="/inscricao_culto_kids" style="color: red;"> INSCRIÇÃO SALA KIDS PRESENCIAL </a>
        </b>
        </label>
      </div>
      <div class="col"></div>
    </div>
  </div>
  -->


  <!--HR
    <div class="col-md-12">
        <div class="row">
            <div class="col">
            </div>
            <div class="col-md-12">
                <hr style="color: #051f5c; background-color: #051f5c; height: 1px; padding-top: 2px;">
            </div>
            <div class="col">
            </div>
        </div>
    </div>
    -->
  <!--BOTOES-->
  <div class="col-md-12">
    <div class="row">
      
      <div class="col-md" style="margin-top: 1px; margin-left: 10%">
        <a routerLink="/nossos-cliques"
          ><img
            width="70%"
            height="40px"
            src="../../assets/img/novos/Cultos2021.png"
        /></a>
      </div>
      <div class="col-md" style="margin-top: 1px; margin-left: 0%">
        <a routerLink="/registros"
          ><img
            width="80%"
            height="40px"
            src="../../assets/img/novos/FOTOS.png"
        /></a>
      </div>
      
      <div class="col-md" style="margin-top: 1px; margin-left: 0%">
        <a routerLink="/consulta">
          <img
            width="80%"
            height="50px"
            src="../../assets/img/novos/Servicos.png"
        /></a>
      </div>
    </div>
  </div>

  <!--HR-->
  <div class="col-md-12">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-12">
        <hr
          style="
            color: #051f5c;
            background-color: #051f5c;
            height: 1px;
            padding-top: 2px;
          "
        />
      </div>
      <div class="col"></div>
    </div>
  </div>

  <div class="col-md-11" style="margin-top: 5px">
    <div class="row" style="padding-left: 15%">
      <div class="col-md" style="margin-top: 1px">
        <a routerLink="/ministerios">
          <img
            class="img-r"
            src="../../assets/img/novos/Ministerios2021.png "
            title="Ministérios"
            alt=""
        /></a>
      </div>
      <div class="col-md" style="margin-top: 1px">
        <a routerLink="/missao-visao-valores">
          <img
            class="img-r"
            src="../../assets/img/novos/MVV2021.png "
            title="Missão,Visão,Valores"
            alt=""
        /></a>
      </div>
      <div class="col-md" style="margin-top: 1px">
        <a routerLink="/pastores">
          <img
            class="img-r"
            src="../../assets/img/novos/ConselhoPastores2021.png "
            title="Conselho de Pastores"
            alt=""
        /></a>
      </div>
      
      <div class="col-md" style="margin-top: 1px">
        <a routerLink="/nossos-seminarios">
          <img
            class="img-r"
            src="../../assets/img/novos/Seminarios2021.png "
            title="Seminários"
            alt=""
        /></a>
      </div>
      <!--<div class="col-md" style="margin-top: 1px; ">
                <a routerLink="/inscricao_culto">
                    <img class="img-r" src="../../assets/img/novos/Inscrições Cultos.png "
                        title="Inscrições Para Culto Presencial" alt=""></a>
            </div>-->
    </div>
  </div>

  <!--HR-->
  <div class="col-md-12">
    <div class="row">
      <div class="col"></div>
      <div class="col-md-12">
        <hr
          style="
            color: #051f5c;
            background-color: #051f5c;
            height: 1px;
            padding-top: 2px;
          "
        />
      </div>
      <div class="col"></div>
    </div>
  </div>

  <div class="col-md" style="margin-left: 50px">
    <div class="row">
      <div
        class="col-md-7"
        style="margin-top: 1px; text-align: center; margin-top: 5px"
      >
        <span
          class="fonte"
          style="
            font-size: 30px;
            padding-left: 70px;
            color: #051f5c;
            font-weight: bold;
          "
          >Onde Estamos?</span
        ><br />
        <span
          class="fonte"
          style="color: #051f5c; font-weight: normal; padding: 0%"
          >A Morada de Deus (IEPMD) está localizada na cidade de Juiz de Fora/MG
          na Rua Bernardo Mascarenhas, Nª 1334, Bairro Fábrica, Próximo ao IFET
          (Antigo CTU).</span
        ><a
          style="margin-left: 5px"
          target="_blank "
          href="https://www.google.com.br/maps/@-21.7416378,-43.3744249,3a,75y,32.52h,64.56t/data=!3m6!1e1!3m4!1sD8h6bxziytkJnut-Z7j4ug!2e0!7i13312!8i6656"
          title="Ver Mapa"
          ><i class="fas fa-map-marker-alt"></i></a
        ><br />
        <br />

        <span
          class="fonte"
          style="
            font-size: 30px;
            padding-left: 70px;
            padding-top: 30px;
            color: #051f5c;
            font-weight: bold;
          "
          >Membro da Morada?</span
        ><br />
        <span
          class="fonte"
          style="color: #051f5c; font-weight: normal; padding: 0%"
          >Você, membro da IEPMD, não deixe de se cadastrar e ter acesso a uma
          área exclusiva para membros. Lembramos que o cadastro tem como
          finalidade ter o controle de todos os membros de nossa igreja para
          envio de comunicados e novidades, não deixe de se cadastrar clicando
          aqui!
          <a routerLink="/cadastros" title="Cadastro"
            ><i class="fas fa-book-open"></i></a></span
        ><br />

        <br />
        <span
          class="fonte"
          style="
            font-size: 30px;
            padding-left: 70px;
            padding-top: 30px;
            color: #051f5c;
            font-weight: bold;
          "
          >Grupo Telegram</span
        ><br />
        <span
          class="fonte"
          style="color: #051f5c; font-weight: normal; padding: 0%"
          >Grupo onde enviamos diariamente um estudo da palavra de Deus como
          tudo que está rolando em nossa igreja!,para participar basta clicar
          aqui!</span
        >
        <a
          style="margin-left: 5px"
          target="_blank "
          href="https://t.me/moradadedeus"
          title="Participar do Grupo"
        >
          <i class="fab fa-telegram-plane"></i></a
        ><br />
        <br />

        <span
          class="fonte"
          style="
            font-size: 30px;
            padding-left: 70px;
            padding-top: 30px;
            color: #051f5c;
            font-weight: bold;
          "
          >Nosso Instagram</span
        ><br />
        <span
          class="fonte"
          style="color: #051f5c; font-weight: normal; padding: 0%"
          >Nossa rede social no instagram,acesse,curta e compartilhe com
          amigos,fique por dentro de tudo que rola em nossa Morada!</span
        >
        <a
          style="margin-left: 5px"
          target="_blank "
          href="https://www.instagram.com/moradajfoficial/"
          title="Acessar nossa rede"
        >
          <i class="fab fa-instagram"></i></a
        ><br />
        <br />
        <span
          class="fonte"
          style="
            font-size: 30px;
            padding-left: 70px;
            padding-top: 30px;
            color: #051f5c;
            font-weight: bold;
          "
          >Fale conosco</span
        ><br />
        <span
          class="fonte"
          style="color: #051f5c; font-weight: normal; padding: 0%"
          >Precisando de entrar em contato conosco? Use nosso canal de
          atendimento para que possamos lhe ajudar de maneira eficaz, para nos
          enviar uma mensagem favor clicar na opção desejada!</span
        >
        <a style="margin-left: 5px" routerLink="/contato" title="E-mail"
          ><i class="fas fa-at"></i></a
        ><br />
      </div>
      <div class="col-sm"></div>
      <div class="col-md-4">
        <div>
          <span
            class="fonte"
            style="
              font-size: 30px;
              padding-left: 70px;
              color: #051f5c;
              font-weight: bold;
            "
            >Horários</span
          ><br />

          <span
            class="fonte"
            style="
              font-size: 20px;
              color: #051f5c;
              font-weight: bold;
              padding: 0%;
              text-align: justify;
              justify-content: space-around;
            "
            >Terça-Feira</span
          ><br />
         <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >2º Terça-Feira Culto de Mulheres (Mulheres Morada de Deus) -
            19:30</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >3º Terça-Feira Culto de Homens (Homens Morada de Deus) -
            19:30</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >4º Terça-Feira Culto da Família (Casais Morada de Deus) -
            19:30</span
          ><br />

          <span
            class="fonte"
            style="
              font-size: 20px;
              color: #051f5c;
              font-weight: bold;
              padding: 0%;
              text-align: justify;
              justify-content: space-around;
            "
            >Quarta-Feira</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >Culto de Cura e Libertação - 19:30</span
          ><br />

          <span
            class="fonte"
            style="
              font-size: 20px;
              color: #051f5c;
              font-weight: bold;
              padding: 0%;
              text-align: justify;
              justify-content: space-around;
            "
            >Quinta-Feira</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >Círculo de Oração - 19:30</span
          ><br />

          

          <span
            class="fonte"
            style="
              font-size: 20px;
              color: #051f5c;
              font-weight: bold;
              padding: 0%;
              text-align: justify;
              justify-content: space-around;
            "
            >Sábado</span
          ><br />

          <!-- <span class="fonte" style="color: #051f5c; font-weight: normal; padding: 0%;">4º Sábado do mês Discipulado de mulheres (Rede Filhas de Leão) - 15:30</span><br> -->
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
          >
            4º Sábado Culto de Mulheres (Mulheres Morada de Deus) - 15:00</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >Culto Jovem (Geração Morada de Deus) - 19:30</span
          ><br />
          <span
            class="fonte"
            style="
              font-size: 20px;
              color: #051f5c;
              font-weight: bold;
              padding: 0%;
              text-align: justify;
              justify-content: space-around;
            "
            >Domingo</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >Culto de louvor e adoração - 08:00 e 19:00</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >Escola Bíblica Dominical - 09:30</span
          ><br />
          <span
            class="fonte"
            style="color: #051f5c; font-weight: normal; padding: 0%"
            >2º Domingo do mês Ceia do Senhor - 08:00 e 19:00</span
          ><br />
          <span
            class="fonte"
            style="
              font-size: 15px;
              color: #051f5c;
              font-weight: bold;
              padding: 0%;
              text-align: justify;
              justify-content: space-around;
            "
            >*Eventuais mudanças são informadas no nosso instagram
            <a
              href="https://www.instagram.com/moradajfoficial/"
              title="Acessar"
              target="_blank"
              >@moradajfoficial</a
            ></span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<button class="voltar-ao-topo" id="back">
  <a class="ancora" style="color: white" href="#"
    ><i class="pi pi-arrow-circle-up"></i
  ></a>
</button>

<!--MODAL-->

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Assine nossa Newsletter
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formcadastro">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Nome:</label>
            <input
              type="text"
              class="form-control"
              id="nome"
              placeholder="informe o Nome"
              name="nome"
              autocomplete="off"
              formControlName="nome"
              [(ngModel)]="contato.nome"
              [ngClass]="{ 'is-invalid': submitted && f.nome.errors }"
            />
            <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
              <div *ngIf="submitted && f.nome.errors.required">
                Preenchimento obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">E-mail:</label>
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="informe o E-mail"
              name="email"
              autocomplete="off"
              formControlName="email"
              [(ngModel)]="contato.email"
              [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="submitted && f.email.errors.required">
                Preenchimento obrigatório
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="registrar()">
          Registrar
        </button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
