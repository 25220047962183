<div class="container">
    <div *ngIf="tipo =='cadastro' && email!=''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong>, estamos a
                poucos passos de liberar acesso
                a
                nossa plataforma.Informamos que todos os dados são para uso exclusivo em nossa plataforma jamais sendo
                compartilhado com terceiros <b>#FiqueTranquilo</b>
            </label>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Favor verificar o email que enviamos para ( <strong>{{email}}</strong>
                ), no mesmo encontra-se uma
                explicação para confirmação de seu cadastro, solicitamos a gentileza de abrir o email e seguir as
                instruções enviadas, caso tenha alguma duvida
                não deixe de nos comunicar, estamos aqui prontos para lhe ajudar!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label>
            <br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/cadastro-membros"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='Seminario' && email=='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado no Seminario com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong>, gostariamos
                de informar que tentamos enviar um email para você mas infelizmente não conseguimos!, Mas não se
                preocupe seu cadastro está salvo corretamente e sua vaga garantida, lembramos que todos os dados
                informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/cadastro-seminario"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>

    <div *ngIf="tipo =='CadastroBatismo' && email!=''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong><br>
                Todos dados aqui informados são compartilhados somente com a gestão da igreja.<br><b>#FiqueTranquilo</b>
            </label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enviamos um email para ( <strong>{{email}}</strong>
                ), confirmando o cadastro!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label>
            <br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/batismo"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='CadastroBatismo' && email==''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado no Seminario com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong><br>
                Todos dados aqui informados são compartilhados somente com a gestão da igreja.<br><b>#FiqueTranquilo</b>
            </label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/batismo"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>


    <div *ngIf="tipo =='Seminario' && email!='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado no Seminario com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong>, gostariamos
                de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para ter certeza de sucesso em seu cadastro, verifique o e-mail que
                enviamos para ( <strong>{{email}}</strong>
                ), lá consta seu registro de inscrição e todos os dados informativos do seminario!, Em caso de duvidas
                não deixe de nos comunicar!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/cadastro-seminario"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>


    <div *ngIf="tipo =='CultoRegistrado' && email=='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado para o Culto com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong>, gostariamos
                de informar que tentamos enviar um email para você mas infelizmente não conseguimos!, Mas não se
                preocupe seu cadastro está salvo corretamente e sua vaga garantida, lembramos que todos os dados
                informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/inscricao_culto"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='CultoRegistrado' && email!='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado para o Culto com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong>, gostariamos
                de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para ter certeza de sucesso em seu cadastro, verifique o e-mail que
                enviamos para ( <strong>{{email}}</strong>
                ), lá consta seu registro de inscrição e todos os dados informativos do culto!, Em caso de duvidas
                não deixe de nos comunicar!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/inscricao_culto"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>


    <div *ngIf="tipo =='CultoKidsRegistrado' && email=='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro para sala KIDS com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo cadastro de seu Filho,<strong>{{nome}}</strong>,
                gostariamos
                de informar que tentamos enviar um email para você mas infelizmente não conseguimos!, Mas não se
                preocupe seu cadastro está salvo corretamente e sua vaga garantida, lembramos que todos os dados
                informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/inscricao_culto_kids"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='CultoKidsRegistrado' && email!='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro para sala KIDS com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo cadastro de seu filho,<strong>{{nome}}</strong>,
                gostariamos
                de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para ter certeza de sucesso em seu cadastro, verifique o e-mail que
                enviamos para ( <strong>{{email}}</strong>
                ), lá consta seu registro de inscrição e todos os dados informativos do culto!, Em caso de duvidas
                não deixe de nos comunicar!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/inscricao_culto_kids"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>

    <div *ngIf="tipo =='curso-casais' && email!='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro Realizado com Sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro casal,<strong>{{nome}}</strong>,
                gostariamos
                de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para ter um controle maior enviamos um email para (
                <strong>{{email}}</strong>
                ), lá consta seu registro de inscrição, Em caso de duvidas
                não deixe de nos comunicar!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="cursos/curso-para-casais"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>

    <div *ngIf="tipo =='curso-casais' && email=='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro Realizado com Sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro casal,<strong>{{nome}}</strong>,
                gostariamos
                de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tentamos enviar um e-mail de confirmação mas não foi possivel,mas não
                se preocupe seu cadastro esta realizado certinho!, Em caso de duvidas
                não deixe de nos comunicar!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/oficinadoamor"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>


  <div *ngIf="tipo =='encontro-casais' && email!='SEM EMAIL'">
    <div class="h">

      <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
      <h5>Cadastro Realizado com Sucesso!</h5>
      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro casal,<strong>{{nome}}</strong>,
        gostariamos
        de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
        compartilhados com terceiros!<b>#FiqueTranquilo</b>
      </label><br>
      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para ter um controle maior enviamos um email para (
        <strong>{{email}}</strong>
        ), lá consta seu registro de inscrição, Em caso de duvidas
        não deixe de nos comunicar!</label>
      <label>Que DEUS lhe abençoe grandiosamente!</label><br>
      <label>Att.</label><br>
      <label><b>Equipe Morada de Deus</b></label><br>
      <br>
      <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
              class="p-button-rounded p-button-secondary" routerLink="/encontro-casais"></button>
      <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
              routerLink="" style="margin-left: 5px;"></button>
    </div>
  </div>

  <div *ngIf="tipo =='encontro-casais' && email=='SEM EMAIL'">
    <div class="h">

      <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
      <h5>Cadastro Realizado com Sucesso!</h5>
      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo seu cadastro casal,<strong>{{nome}}</strong>,
        gostariamos
        de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
        compartilhados com terceiros!<b>#FiqueTranquilo</b>
      </label><br>
      <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tentamos enviar um e-mail de confirmação mas não foi possivel,mas não
        se preocupe seu cadastro esta realizado certinho!, Em caso de duvidas
        não deixe de nos comunicar!</label>
      <label>Que DEUS lhe abençoe grandiosamente!</label><br>
      <label>Att.</label><br>
      <label><b>Equipe Morada de Deus</b></label><br>
      <br>
      <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
              class="p-button-rounded p-button-secondary" routerLink="/encontro-casais"></button>
      <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
              routerLink="" style="margin-left: 5px;"></button>
    </div>
  </div>




  <div *ngIf="tipo =='CongressoInfantilRegistrado' && email=='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo cadastro de seu Filho,<strong>{{nome}}</strong>,
                gostariamos
                de informar que tentamos enviar um email para você mas infelizmente não conseguimos!, Mas não se
                preocupe seu cadastro está salvo corretamente e sua vaga garantida, lembramos que todos os dados
                informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/seminario-infantil"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='CongressoInfantilRegistrado' && email!='SEM EMAIL'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado com Sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ficamos feliz pelo cadastro de seu filho,<strong>{{nome}}</strong>,
                gostariamos
                de informar que seu cadastro foi realizado com sucesso e que todos os dados informados jamais são
                compartilhados com terceiros!<b>#FiqueTranquilo</b>
            </label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Para ter certeza de sucesso em seu cadastro, verifique o e-mail que
                enviamos para ( <strong>{{email}}</strong>
                ), lá consta seu registro de inscrição e todos os dados informativos no cadastro!, Em caso de duvidas
                não deixe de nos comunicar!</label>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/seminario-infantil"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>




    <div *ngIf="tipo =='cadastro' && email==''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Parabéns!</h3>
            <h5>Cadastro realizado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz pelo seu cadastro,<strong>{{nome}}</strong>, estamos a
                poucos passos de liberar acesso
                a nossa plataforma.Informamos que todos os dados são para uso exclusivo em nossa plataforma jamais sendo
                compartilhado com terceiros <b>#FiqueTranquilo</b>
            </label>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Como não informou email no cadastro, enviamos uma notificação a nossa
                administração para analise e
                liberação de seu cadastro, normalmente e feito dentro de 48hs mas estaremos liberando o mais breve
                possivel, caso tenha alguma duvida não deixe de nos comunicar, estamos aqui prontos para lhe
                ajudar!</label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/cadastro-membros"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='contato' && email==''">
        <div class="h">
            <h3><img src="../../assets/img/email.jpeg" style="width: 50px; margin-right: 5px;">Obrigado!</h3>
            <h5>Contato registrado e enviado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Olá,<strong>{{nome}}</strong>, Gostaríamos de agradecer seu contato, e
                informar que logo logo iremos
                analisar o motivo de seu contato.
            </label>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ressaltamos que todos os dados enviados jamais são compartilhados com
                terceiros
                <b>#FiqueTranquilo</b>
            </label>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Desde já nos colocamos a disposição para eventuais esclarecimentos que
                se façam necessarios.</span><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                class="p-button-rounded p-button-secondary" routerLink="/contato"></button>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>

        </div>
        <div *ngIf="tipo =='contato' && email!=''">
            <div class="h">
                <h3><img src="../../assets/img/email.jpeg" style="width: 50px; margin-right: 5px;">Obrigado!</h3>
                <h5>Contato registrado e enviado com sucesso!</h5>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Olá,<strong>{{nome}}</strong>, Gostaríamos de agradecer seu
                    contato, e
                    informar que logo logo iremos
                    analisar o motivo de seu contato.
                </label>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ressaltamos que todos os dados enviados jamais são compartilhados
                    com
                    terceiros
                    <b>#FiqueTranquilo</b>
                </label>
                <div *ngIf="copia==true">
                    <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Como solicitou uma copia gostariamos de informar que a mesma
                        foi
                        enviada para (
                        <strong>{{email}}</strong> ), caso tenha alguma duvida não deixe de nos comunicar, estamos aqui
                        sempre prontos para lhe ajudar!
                    </label>
                </div>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Desde já nos colocamos a disposição para eventuais esclarecimentos
                    que
                    se façam necessarios.</span><br>
                <label>Que DEUS lhe abençoe grandiosamente!</label><br>
                <label>Att.</label><br>
                <label><b>Equipe Morada de Deus</b></label><br>
                <br>

                <button pButton pRipple type="button" icon="pi pi-refresh" title="Retornar"
                    class="p-button-rounded p-button-secondary" routerLink="/contato"></button>
                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="" style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>
    <div *ngIf="tipo =='confirmacao'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Confirmação
                Efetuada!
            </h3>
            <h5>Confirmação efetuada com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por sua confirmação,<strong>{{nome}}</strong>, seus
                cadastro foi confirmado com sucesso
                <br>Há já ia me esquecendo!, seu dados anotados jamais serão compartilhados tá!
                <b>#FiqueTranquilo</b>
            </label>

            <div *ngIf="email==null">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Não consegui lhe enviar email porque no cadastro deixou em
                    branco
                    :( ,mas gostaria de informar
                    que esta tudo certinho!<br></label>
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Utilize seu login e senha criados no cadastro para utilização em nossa plataforma,Quaisquer
                    duvida
                    não deixe de nos comunicar, estamos aqui prontos para lhe ajudar!</label><br>
                <label>Que DEUS lhe abençoe grandiosamente!</label><br>
                <span>Att.</span><br>
                <label><b>Equipe Morada de Deus</b></label><br>
                <br>
                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="" style="margin-left: 5px;"></button>
            </div>

            <div *ngIf="email.length >3">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os dados para acesso foram enviados para o email
                    <strong>{{email}}</strong>,Quaisquer duvida não
                    deixe de nos comunicar, estamos aqui prontos para lhe ajudar!</label>
                <label>Que DEUS lhe abençoe grandiosamente!</label><br>
                <span>Att.</span><br>
                <label><b>Equipe Morada de Deus</b></label><br>
            </div>
            <br>

            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>

    <div *ngIf="tipo =='problema'  && email==''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Confirmação
                Efetuada!
            </h3>
            <h5>Problema registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por colaborar conosco,<strong>{{nome}}</strong>,
                Neste
                momento já estou comunicando
                nossa equipe para analise e ajuste o mais breve possivel!<br>Há já ia me esquecendo!, seu dados
                enviados
                jamais serão compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Não consegui lhe enviar email porque no cadastro deixou em branco
                :(
                ,tentaremos contato por telefone
                informado!,mas gostaria de informar que esta
                tudo certinho!</label><br>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Iremos entrar em contato pelo telefone que deixou no
                cadastro!</label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='problema'  && email!=''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Confirmação
                Efetuada!
            </h3>
            <h5>Problema registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por colaborar conosco,<strong>{{nome}}</strong>,
                Neste
                momento já estou comunicando
                nossa equipe para analise e ajuste o mais breve possivel!<br>Há já ia me esquecendo!, seu dados
                enviados
                jamais serão compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Iremos entrar em contato pelo email que deixou anotado,
                <strong>{{email}}</strong>, logo
                logo!</label><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='visitante' && email!=''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Visita Registrada!
            </h3>
            <h5>Visita foi registrada com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por sua visita,<strong>{{nome}}</strong>, Espero que
                tenha gostado de nosso espaço e
                pela obra que o Senhor faz em nosso igreja!<br>Fique a vontade para voltar sempre!, nós estaremos
                sempre
                de
                braços abertos para lhe receber.<br>Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <div *ngIf="copia==true">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Como solicitou uma copia gostariamos de informar que a mesma
                    foi
                    enviada para (
                    <strong>{{email}}</strong> ), caso tenha alguma duvida não deixe de nos comunicar, estamos aqui
                    sempre prontos para lhe ajudar!
                </label>
            </div>
            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='visitante' && email==''">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Visita Registrada!
            </h3>
            <h5>Visita foi registrada com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por sua visita,<strong>{{nome}}</strong>, Espero que
                tenha gostado de nosso espaço e
                pela
                obra que o Senhor faz em nosso igreja!<br>Fique a vontade para voltar sempre!, nós estaremos sempre
                de
                braços abertos para lhe receber.<br>Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <div *ngIf="copia==true">
                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Solicitou copia mas seu email esta errado e por este motivo não
                    consegui enviar uma copia, mas
                    fique
                    tranquilo, recebi e já encaminhei para nossa equipe sua visita!</label>
            </div><br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>




    <div *ngIf="tipo =='encontro-mulheres' && email!=''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>, Logo um de
                nossos organizações entrará em contato para esclarecimentos de duvidas e observações que se façam
                necessarias.
                <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá!<b>#FiqueTranquilo</b>
            </label>

            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Foi enviado um email para <strong>{{email}}</strong> confirmando este
                cadastro!</label>

            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/encontro-mulheres" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='encontro-mulheres' && email==''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>, Logo um de
                nossos organizadores entrará em contato para esclarecimentos de duvidas e observações que se façam
                necessarias.
                <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/encontro-mulheres" style="margin-left: 5px;"></button>
        </div>
    </div>




    <div *ngIf="tipo =='CongressoMulheres' && email!=''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verifique sua caixa de e-mail para comprovar o recebimento do Ticket de entrada<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Analise sua caixa de email por completo ( caixa de entrada e spam )<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso não tenha recebido tente efetuar o cadastro novamente.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Observações Importantes!</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- No dia do congresso lembre-se de apresentar os seguintes itens (Ticker + Documento com foto + 2KG de alimentos não perecíveis).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- (NÃO) e permitido levar crianças menores de 12 anos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- As crianças que atendam ao requisito acima devem ser cadastradas com seus respectivos documentos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Caso leve criança que não atenda aos requisitos acima,a entrada poderá ser barrada!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido cadastro no dia do congresso!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido a entrada sem o TICKET enviado e demais dados necessarios.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Os alimentos serão trocados pela pulseira do congresso.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Se possível, chegue mais cedo!,valide sua entrada e evite imprevistos!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Em caso de dúvidas por favor procure a (Pra.Lucilene) ou (Obreira Edilaine).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label> <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/congresso/congresso-mulheres" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='CongressoMulheres' && email==''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verifique sua caixa de e-mail para comprovar o recebimento do Ticket de entrada<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Analise sua caixa de email por completo ( caixa de entrada e spam )<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso não tenha recebido tente efetuar o cadastro novamente.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Observações Importantes!</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- No dia do congresso lembre-se de apresentar os seguintes itens (Ticker + Documento com foto + 2KG de alimentos não perecíveis).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- (NÃO) e permitido levar crianças menores de 12 anos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- As crianças que atendam ao requisito acima devem ser cadastradas com seus respectivos documentos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Caso leve criança que não atenda aos requisitos acima,a entrada poderá ser barrada!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido cadastro no dia do congresso!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido a entrada sem o TICKET enviado e demais dados necessarios.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Os alimentos serão trocados pela pulseira do congresso.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Se possível, chegue mais cedo!,valide sua entrada e evite imprevistos!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Em caso de dúvidas por favor procure a (Pra.Lucilene) ou (Obreira Edilaine).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/congresso/congresso-mulheres" style="margin-left: 5px;"></button>
        </div>
    </div>







    <div *ngIf="tipo =='CongressoHomens' && email!=''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verifique sua caixa de e-mail para comprovar o recebimento do Ticket de entrada<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Analise sua caixa de email por completo ( caixa de entrada e spam )<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso não tenha recebido tente efetuar o cadastro novamente.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Observações Importantes!</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- No dia do congresso lembre-se de apresentar os seguintes itens (Ticker + Documento com foto + 1 litro de Leite OU 1 litro de Óleo).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- (NÃO) e permitido levar crianças menores de 12 anos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- As crianças que atendam ao requisito acima devem ser cadastradas com seus respectivos documentos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Caso leve criança que não atenda aos requisitos acima,a entrada poderá ser barrada!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido cadastro no dia do congresso!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido a entrada sem o TICKET enviado e demais dados necessarios.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Os alimentos serão trocados pela pulseira do congresso.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Se possível, chegue mais cedo!,valide sua entrada e evite imprevistos!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Em caso de dúvidas por favor procure o (Obreiro Anderson ou Luiz).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label> <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/congresso/congresso" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='CongressoHomens' && email==''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verifique sua caixa de e-mail para comprovar o recebimento do Ticket de entrada<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Analise sua caixa de email por completo ( caixa de entrada e spam )<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso não tenha recebido tente efetuar o cadastro novamente.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Observações Importantes!</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- No dia do congresso lembre-se de apresentar os seguintes itens (Ticker + Documento com foto + 1 Litro de Leite ou 1 Litro de Òleo).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- (NÃO) e permitido levar crianças menores de 12 anos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- As crianças que atendam ao requisito acima devem ser cadastradas com seus respectivos documentos.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Caso leve criança que não atenda aos requisitos acima,a entrada poderá ser barrada!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido cadastro no dia do congresso!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido a entrada sem o TICKET enviado e demais dados necessarios.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Os alimentos serão trocados pela pulseira do congresso.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Se possível, chegue mais cedo!,valide sua entrada e evite imprevistos!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Em caso de dúvidas por favor procure o (Obreiro Anderson ou Luiz).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/congresso/congresso" style="margin-left: 5px;"></button>
        </div>
    </div>




    <div *ngIf="tipo =='CongressoJovens' && email!=''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verifique sua caixa de e-mail para comprovante de cadastro<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Analise sua caixa de email por completo ( caixa de entrada e spam )<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso não tenha recebido tente efetuar o cadastro novamente.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Observações Importantes!</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- No dia da conferência lembre-se de apresentar os seguintes itens (Documento com foto + 2KG de Arroz + 1KG de feijão).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido cadastro no dia do congresso!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Se possível, chegue mais cedo!,valide sua entrada e evite imprevistos!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Em caso de dúvidas por favor procure a (Obreira Daniely ou Obreiro Carlos).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label> <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/conferencia" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='CongressoJovens' && email==''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Verifique sua caixa de e-mail para comprovante de cadastro<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Analise sua caixa de email por completo ( caixa de entrada e spam )<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Caso não tenha recebido tente efetuar o cadastro novamente.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Observações Importantes!</b><br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- No dia da conferência lembre-se de apresentar os seguintes itens (Documento com foto + 2KG de Arroz + 1KG de feijão).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Não será permitido cadastro no dia do congresso!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Se possível, chegue mais cedo!,valide sua entrada e evite imprevistos!.<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Em caso de dúvidas por favor procure a (Obreira Daniely ou Obreiro Carlos).<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/conferencia" style="margin-left: 5px;"></button>
        </div>
    </div>







    <div *ngIf="tipo =='encontro-homens' && email!=''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>, Logo um de
                nossos organizações entrará em contato para esclarecimentos de duvidas e observações que se façam
                necessarias.
                <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá!<b>#FiqueTranquilo</b>
            </label>

            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Foi enviado um email para <strong>{{email}}</strong> confirmando este
                cadastro!</label>

            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>

            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/encontro-homens" style="margin-left: 5px;"></button>
        </div>
    </div>
    <div *ngIf="tipo =='encontro-homens' && email==''">
        <div class="h">
            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Cadastro Registrado com
                Sucesso!
            </h3>
            <h5>Cadastro foi registrado com sucesso!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ficamos feliz por seu cadastro,<strong>{{nome}}</strong>, Logo um de
                nossos organizadores entrará em contato para esclarecimentos de duvidas e observações que se façam
                necessarias.
                <br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Há já ia me esquecendo!, seu dados anotados jamais serão
                compartilhados tá! <b>#FiqueTranquilo</b>
            </label>
            <br>
            <label>Que DEUS lhe abençoe grandiosamente!</label><br>
            <span>Att.</span><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="/encontro-homens" style="margin-left: 5px;"></button>
        </div>
    </div>



    <div *ngIf="tipo =='cancelamento'">
        <div class="h">

            <h3><img src="../../assets/img/sucess.jpeg" style="width: 30px; margin-right: 5px;">Entrada Autorizada!
            </h3>
            <label>Cadastro confirmado com sucesso!<br>Que Deus lhe abençoe!<br>Att.<br><b>Equipe Morada de
                    Deus</b></label>
            <br>

            <button pButton pRipple type="button" icon="pi pi-home" title="Home" class="p-button-rounded p-button-help"
                routerLink="" style="margin-left: 5px;"></button>
        </div>
    </div>


    <div *ngIf="tipo=='DOCUMENTO'">
        <div class="h">
            <h2 style="color: red; font-style: oblique;"><img src="../../assets/img/sucess.jpeg"
                    style="width: 100px; margin-right: 5px;">ENTRADA AUTORIZADA E CONFIRMADA COM SUCESSO!</h2>
            <h5>Que bom que está aqui!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entre com espectativa do que Deus irá fazer! Otimo Culto!.</label>
            <br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <br>
            <div>

                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="/checkout/documento"
                    style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>

    <div *ngIf="tipo=='DOCUMENTO400'">
        <div class="h">
            <h2 style="color: red; font-style: oblique;">ENTRADA NÃO PERMITIDA!</h2>
            <h5>ESTE QRCODE ESTÀ ERRADO!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pedimos sinceras desculpas por este inconveniente!, mas este QRCODE não
                encontra-se na nossa base de dados!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <br>
            <div>

                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="/checkout/documento"
                    style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>

    <div *ngIf="tipo=='DOCUMENTO422'">
        <div class="h">
            <h2 style="color: red; font-style: oblique;">ENTRADA JÀ FOI REALIZADA!</h2>
            <h5>ESTE CADASTRO JÁ FUI UTILIZADO EM ENTRADA ANTERIOR</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pedimos sinceras desculpas por este inconveniente!, mas este QRCODE já
                foi
                utilizado para entrada anteriormente
                !</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <br>
            <div>

                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="/checkout/documento"
                    style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>

    <div *ngIf="tipo=='QRCODE'">
        <div class="h">
            <h2 style="color: red; font-style: oblique;"><img src="../../assets/img/sucess.jpeg"
                    style="width: 100px; margin-right: 5px;">ENTRADA AUTORIZADA E CONFIRMADA COM SUCESSO!</h2>
            <h5>Que bom que está aqui!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Entre com espectativa do que Deus irá fazer! Otimo Culto!.</label>
            <br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <br>
            <div>

                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="/checkout/documento"
                    style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>

    <div *ngIf="tipo=='QRCODE400'">
        <div class="h">
            <h2 style="color: red; font-style: oblique;">ENTRADA NÃO PERMITIDA!</h2>
            <h5>ESTE QRCODE ESTÀ ERRADO!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pedimos sinceras desculpas por este inconveniente!, mas este QRCODE não
                encontra-se na nossa base de dados!</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <br>
            <div>

                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="" style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>

    <div *ngIf="tipo=='QRCODE422'">
        <div class="h">
            <h2 style="color: red; font-style: oblique;">ENTRADA JÀ FOI REALIZADA!</h2>
            <h5>ESTE CADASTRO JÁ FUI UTILIZADO EM ENTRADA ANTERIOR</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pedimos sinceras desculpas por este inconveniente!, mas este QRCODE já
                foi
                utilizado para entrada anteriormente
                !</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <br>
            <div>

                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="" style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>



    <div *ngIf="tipo=='Erro'">
        <div class="h">
            <h2 style="color: red; font-style: oblique;"><img src="../../assets/img/404.jpeg"
                    style="width: 100px; margin-right: 5px;">Pagina não encontrada!</h2>
            <h5>Isto não foi legal!</h5>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pedimos sinceras desculpas por este inconveniente!</label>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Já comuniquei ao meu criador para me ajudar a resolver a duvida que
                encontrei e não consegui resolver
                sozinho, logo logo ele me ensina e isto não vai mais ocorrer.</label>
            <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Até que ele posso me explicar deixe-me levar você a tela inicial,
                para
                isto basta clicar no botão
                azul
                abaixo ( HOME ).</label><br>
            <label>Att.</label><br>
            <label><b>Equipe Morada de Deus</b></label><br>
            <br>
            <br>
            <div>

                <button pButton pRipple type="button" icon="pi pi-home" title="Home"
                    class="p-button-rounded p-button-help" routerLink="" style="margin-left: 5px;"></button>
            </div>
        </div>
    </div>
</div>
